import React, {Component} from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Col, Grid, Row } from "react-flexbox-grid";
import { getInventoryTop10ForYear, getInventoryTop10ThisMonth, getInventorySoldStats, getInventoryCategories } from "../actions";
import * as selectors from "../selectors";
import {DateRangePicker} from "./DateRange/";
import DahboardLayout from "./DahboardLayout";
import {ALL_DASHBOARD_MEASUREMENTS, DASHBOARD_ITEMS, INVENTORY_TYPES} from "../constants";
import BarChart from "./Barchart";
import LineChart from "./Linechart";
import moment from "moment";
import {addDays} from "date-fns";
import Select from "react-select";

import * as api from '../api';

class Inventory extends Component {
    onDateRangeChange = (which, payload) => {
        this.setState({selection: which.selection}, () => {
            const { selectedType, selectedCategory, selectedSKU } = this.state;
            if(which.selection.startDate && which.selection.endDate) {
                this.props.getInventorySoldStats(moment(which.selection.startDate), moment(which.selection.endDate), selectedType.value, selectedCategory.value, selectedSKU?.label ?? '');
            }
        });
    };
    onSourceClick = (node) => {
        this.props.sourceSelected(node.data.source);
    };
    handleInputChange = (inputValue) => {
        const { selectedCategory, selectedType } = this.state;
        this.setState({ selectedSKU: inputValue }, () => {
            this.props.getInventorySoldStats(moment(this.state.selection.startDate), moment(this.state.selection.endDate), selectedType.value, selectedCategory.value, inputValue?.label ?? '');
        });
    }

    constructor(props) {
        super(props);
        this.state = {
            selection: {
                startDate: addDays(new Date(), -7),
                endDate: new Date(),
                key: 'selection',
            },
            selectedType: INVENTORY_TYPES.any,
            selectedCategory: { label: "ALL", value: 'ALL' },
            selectedSKU: ''
        };
    }

    UNSAFE_componentWillMount() {
        const { selectedType, selectedCategory } = this.state;
        this.props.getInventorySoldStats(moment().startOf('day').add(-7, 'days'), moment().startOf('day'), selectedType.value, selectedCategory.value);
        this.props.getInventoryTop10ForYear(selectedType.value, selectedCategory.value);
        this.props.getInventoryTop10ThisMonth(selectedType.value, selectedCategory.value);
        this.props.getInventoryCategories();
    }

    onTypeChange = (selected) => {
        const { selectedCategory, selectedSKU } = this.state;
        this.setState({ selectedType: selected }, () => {
            this.props.getInventorySoldStats(moment().startOf('day').add(-7, 'days'), moment().startOf('day'), selected.value, selectedCategory.value, selectedSKU?.label ?? '');
            this.props.getInventoryTop10ForYear(selected.value, selectedCategory.value);
            this.props.getInventoryTop10ThisMonth(selected.value, selectedCategory.value);
        });
    };

    onCategoryChange = (selected) => {
        const { selectedType, selectedSKU } = this.state;
        this.setState({ selectedCategory: selected }, () => {
            this.props.getInventorySoldStats(moment().startOf('day').add(-7, 'days'), moment().startOf('day'), selectedType.value, selected.value, selectedSKU?.label);
            this.props.getInventoryTop10ForYear(selectedType.value, selected.value);
            this.props.getInventoryTop10ThisMonth(selectedType.value, selected.value);
        });
    };

    render() {
        const {inventoryStatsForYear, inventorySalesForYear, inventoryStatsForMonth, inventorySalesForMonth, inventoryTop10ForYearLoading, inventoryTop10ThisMonthLoading, inventorySoldStats, inventorySoldStatsLoading, inventoryCategories } = this.props;

        return (

            <Grid fluid className="dashboardContainer">
                <p><b>Please select Inventory Category to show</b></p>
                <Row>
                    {/* <Col md={3}>
                        <Select options={_.map(INVENTORY_TYPES, x => x)}
                            onChange={this.onTypeChange}
                            placeholder={"Select Type"}
                            value={this.state.selectedType}
                        />
                    </Col> */}
                    <Col md={3}>
                        <Select options={[{ label: "ALL", value: "ALL" }, ..._.map(inventoryCategories, i => ({ label: i.categoryName, value: i.categoryName }))]}
                            onChange={this.onCategoryChange}
                            placeholder={"Select Category"}
                            value={this.state.selectedCategory}
                        />
                    </Col>
                </Row>
                <Row className="dashboardHeader">
                    <Col>
                        <div>
                            <DateRangePicker
                                onChange={this.onDateRangeChange}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                className={'PreviewArea'}
                                months={3}
                                ranges={[this.state.selection]}
                                direction="horizontal"
                            />
                        </div>
                    </Col>
                </Row>
                <DahboardLayout>
                    <div key={DASHBOARD_ITEMS.Inventory1}>
                        <BarChart indexBy={'channelSKU'}
                                  data={inventoryStatsForMonth}
                                  loading={inventoryTop10ForYearLoading}
                                  onClick={this.onSourceClick}
                                  title="Top 10 SKUs sold (processed) within last 30 days (sold and orders count)"
                                  textDisplayKey="channelSKU"
                                  groupMode={"grouped"}
                                  colors={{"scheme":"nivo"}}
                        />
                    </div>
                    <div key={DASHBOARD_ITEMS.Inventory2}>
                        <BarChart indexBy={'channelSKU'}
                                  data={inventorySalesForMonth}
                                  loading={inventoryTop10ThisMonthLoading}
                                  title="Top 10 SKUs sold (processed) within last 30 days (revenue and profit)"
                                  textDisplayKey="channelSKU"
                                  groupMode={"grouped"}
                                  colors={{"scheme":"accent"}}
                        />
                    </div>
                    <div key={DASHBOARD_ITEMS.Inventory3}>
                        <BarChart indexBy={'channelSKU'}
                                  data={inventoryStatsForYear}
                                  loading={inventoryTop10ForYearLoading}
                                  onClick={this.onSourceClick}
                                  title="Top 10 SKUs sold (processed) within last 365 days (sold and orders count)"
                                  textDisplayKey="channelSKU"
                                  groupMode={"grouped"}
                                  colors={{"scheme":"nivo"}}
                        />
                    </div>
                    <div key={DASHBOARD_ITEMS.Inventory4}>
                        <BarChart indexBy={'channelSKU'}
                                  data={inventorySalesForYear}
                                  loading={inventoryTop10ThisMonthLoading}
                                  title="Top 10 SKUs sold (processed) within last 365 days (revenue and profit)"
                                  textDisplayKey="channelSKU"
                                  groupMode={"grouped"}
                                  colors={{"scheme":"accent"}}
                        />
                    </div>
                    <div key={DASHBOARD_ITEMS.Inventory5}>
                        <LineChart
                            data={inventorySoldStats} title="Total sold (processed) by SKU Daily (selected date range)"
                            textDisplayKey="id"
                            legendLeftText={ALL_DASHBOARD_MEASUREMENTS[0].label}
                            customColors={{ scheme: 'paired' }}
                            bottomLegend={"Date"}
                            loading={inventorySoldStatsLoading}
                            itemInput={true}
                            onSelectOptionSelected={this.handleInputChange}
                        />
                    </div>
                </DahboardLayout>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        inventoryStatsForYear: selectors.getInventoryStatsForYear(state),
        inventorySalesForYear: selectors.getInventorySalesForYear(state),
        inventoryStatsForMonth: selectors.getInventoryStatsForMonth(state),
        inventorySalesForMonth: selectors.getInventorySalesForMonth(state),
        inventoryTop10ForYearLoading: selectors.getInventoryTop10ForYearLoading(state),
        inventoryTop10ThisMonthLoading: selectors.getInventoryTop10ThisMonthLoading(state),
        inventorySoldStats: selectors.getInventorySoldStats(state),
        inventorySoldStatsLoading: selectors.getInventorySoldStatsLoading(state),
        inventoryCategories: selectors.getInventoryCategories(state),
    };
}

const mapDispatchToProps = {
    getInventoryTop10ForYear,
    getInventoryTop10ThisMonth,
    getInventorySoldStats,
    getInventoryCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);